import * as Trix from 'trix';
require('@rails/actiontext');

Trix.config.blockAttributes.heading1.tagName = 'h3';

Object.assign(Trix.config.lang, {
  GB: 'GB',
  KB: 'KB',
  MB: 'MB',
  PB: 'PB',
  TB: 'TB',
  attachFiles: 'ファイルを添付',
  bold: '太字',
  bullets: '箇条書き',
  byte: 'Byte',
  bytes: 'Bytes',
  captionPlaceholder: 'キャプションを入力してください',
  code: 'コード',
  heading1: '見出し',
  indent: 'インデントする',
  italic: 'イタリック',
  link: 'リンク',
  numbers: '段落番号',
  outdent: 'インデントを解除',
  quote: '引用',
  redo: 'やり直し',
  remove: '',
  strike: '取消線',
  undo: '取消',
  unlink: '取消',
  url: 'URL',
  urlPlaceholder: 'リンクを入力してください',
});

window.addEventListener('turbo:load', () => {
  // Trixエディタ内のゴミ箱アイコン
  const deleteButton = document.querySelector('[data-trix="js-button-delete"]');
  const trixTextEditor = document.querySelector('[data-trix="js-trix-editor"]');
  if (deleteButton) {
    deleteButton.addEventListener('click', () => {
      trixTextEditor.innerHTML = '';
    });
  }

  // Trixによるファイルアップロードを制限する
  window.addEventListener('trix-file-accept', function (event) {
    const file = event.file;
    const mimeType = file.type;
    const allowedMimeTypes = ['image/jpeg', 'image/png', 'image/svg+xml', 'image/webp', 'image/gif'];
    const size = file.size;
    const maxFileSize = 10485760; // 10 MB

    if (!allowedMimeTypes.includes(mimeType)) {
      event.preventDefault();
      alert('画像のみがアップロードできます');
    }
    if (size > maxFileSize) {
      event.preventDefault();
      alert('ファイルサイズが制限(10MB)を超えています');
    }
  });
});
