// TODO: Refactor and combine this with toggle_visibility_controller.js
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  target;

  connect() {
    this.target = document.querySelector(this.element.dataset.targetSelector)
  }

  toggle(event) {
    event.preventDefault()
    this.target.classList.toggle('display-none')
    this.element.classList.toggle('selected')

  }
}
