// 使用例
//
// <div data-controller="activity" data-url="[問い合わせエンドポイント]" data-polling-interval="5" >
//   <div data-activity-target='status'>
//     [ここにレスポンスのHTMLを表示する]
//   </div>
// </div>
//
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'status' ]
  #intervalHandle

  connect() {
    const pollingInterval = parseInt(this.element.dataset.pollingInterval); // in seconds

    this.#intervalHandle = setInterval(
      () => {
        this.#sendAndReceiveActivity();
      },
    pollingInterval * 1000);
  }

  disconnect() {
    clearInterval(this.#intervalHandle);
  }

  #sendAndReceiveActivity() {
    fetch(this.#url(), {
      method: 'PUT',
      cache: 'no-cache',
      headers: {
        'X-CSRF-Token': this.#csrfToken(),
        'Content-Type': 'text/html',
      },
      body: '',
    }).then(response => {
      if (response.ok) {
        return response.text();
      }
    }).then(html => {
      if (html && this.statusTarget) {
        this.statusTarget.innerHTML = html;
      }
    }) ;
  }

  #url() {
    return this.element.dataset.url;
  }

  #csrfToken() {
    return document.getElementsByName('csrf-token')[0].content;
  }
}
