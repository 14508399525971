import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ "checkBox" ];
  connect() {
  }

  selectAll() {
    const elements = this.checkBoxTargets;
    elements.forEach( element => element.checked = true )
  }

  deSelectAll() {
    const elements = this.checkBoxTargets;
    elements.forEach( element => element.checked = false )
  }
}
