window.addEventListener('turbo:load', () => {
  const chartCanvases = document.querySelectorAll('.progress-chart');

  chartCanvases.forEach((chartCanvas) => {
    const chartData = JSON.parse(chartCanvas.dataset.chartData);
    const chartObjectIdentifier = `chartCanvas-${chartCanvas.id}`;
    const chartLabel = chartCanvas.dataset.labelText

    function colorize() {
      return (context) => {
        return (context.index === chartData.current_user_position) ? '#00a0e9' : '#818181';
      }
    }

    chartCanvas.height = (chartData.data.length + 1) * 30;

    const context = chartCanvas.getContext('2d');

    new Chart(context, {
      type: 'bar',
      data: {
        labels: chartData.labels,
        datasets: [
          {
            label: chartLabel,
            data: chartData.data,
            backgroundColor: colorize(),
            borderRadius: 4,
            order: 1
          }
        ]
      },
      options: {
        indexAxis: 'y',
        responsive: true,
        scales: {
          x: {
            ticks: {
              stepSize: 1
            }
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          }
        }
      },
    });
  });
})
