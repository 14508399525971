import {Controller} from '@hotwired/stimulus';
/*
* TitleController sets the document.title using JavaScript, typically in response to
* a TurboFrame being loaded with the 'turbo-action: advance' attribute. The 'advance' attribute
* will set the location bar URL after a new TurboFrame is loaded. However, it will not set the
* title (which makes a ton of sense since the <title> tag may not even be present in a TurboFrame response).
* This is why we want to set the title after the TurboFrame content loads.
*
* Usage:
*   <span data-title="[New Title String]" data-controller="title"></span>
*
* If we are using TurboFrames, put the above tag somewhere inside the TurboFrame content.
*
* */
export default class extends Controller {
    connect() {
        const title = this.element.dataset.title
        if (title) {
            document.title = title;
        }
    }
}
