// Controller to disable submit buttons
// after the request has been submitted.
//
// Example:
// <div data-controller="submit-disable" data-disabled-text="Uploading..." data-action="submit->submit-disable#disable">
//   <form .... >
//     ...
//     <input type="submit" value="Upload">
//   </form>
// </div>
//
// You can use both input tags and button tags as long as they
// have `type=submit`.
//
// We also assume that the `is-disable` CSS class give the appearance of a
// disabled button.
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  disable(event) {
    const submitButtons = this.element.querySelectorAll('[type=submit]')
    const disableText = this.element.dataset.disabledText

    submitButtons.forEach((sb) => {
      sb.disabled = true
      sb.classList.add('is-disable')
      if (disableText) {
        // We allow both Button tags and Submit tags
        // which have different ways of setting text.
        if (sb.name == 'button') {
          sb.innerHTML = disableText
        } else {
          sb.value = disableText
        }
      }
    })
  }
}
