import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    data: String,
    color: String,
    maxBarSize: Number,
    barUnit: String,
  }
  #graphHeight;
  #graphWidth;
  #graphOffsetX;
  #labelFontSize;
  #labelTextColorX;
  #labelAreaHeightX;
  #graphOffsetY;
  #canvas;
  #data;
  #color;
  #gridColor;
  #maxBarSize;
  #barUnit;

  connect() {
    // If the current page is already in Turbo's cache, then `connect()` will
    // be called twice -- first when the cache loads, and second when the data
    // is received from the server.
    // To prevent this causing the animation to happen twice, we prevent this
    // animating when reading from the cache.
    if (document.documentElement.hasAttribute("data-turbo-preview")) {
      return;
    }
    this.#maxBarSize = this.maxBarSizeValue;
    this.#barUnit = this.barUnitValue;
    this.#color = this.colorValue;
    this.#gridColor ="#666666";
    this.#graphOffsetX = 10;
    this.#graphOffsetY = 2;
    this.#labelFontSize = 16;
    this.#labelAreaHeightX = 20;
    this.#labelTextColorX = "#BBBBBB";

    this.#data = JSON.parse(this.dataValue)
    this.#intializeCanvas();
    this.#initializeGraphHeightAndWidth();

    const ctx = this.#canvas.getContext('2d');

    this.#drawGridLines(ctx);
    this.#drawMaxBarSizeLabel(ctx);

    this.#drawBarsAndLabels(ctx);
  }

  #drawGridLines(ctx) {
    ctx.save();
    ctx.lineWidth = 2;
    ctx.strokeStyle = this.#gridColor;
    ctx.setLineDash([5, 5]);
    ctx.beginPath();
    for(let i = 0; i <= 4; i++) {
      ctx.moveTo(0, this.#graphHeight / 4 * i + this.#graphOffsetY);
      ctx.lineTo(this.#canvas.width, this.#graphHeight/ 4 * i + this.#graphOffsetY);
    }
    ctx.stroke();
    ctx.restore();
  }

  #drawMaxBarSizeLabel(ctx) {
    ctx.font = this.#labelFont();
    ctx.fillStyle = this.#labelTextColorX;
    ctx.fillText(`${this.#maxBarSize} ${this.#barUnit}`, 0, this.#labelAreaHeightX);
  }
  #intializeCanvas() {
    this.#canvas = this.element;

    // Get the DPR and size of the canvas
    const dpr = window.devicePixelRatio;
    const rect = this.#canvas.getBoundingClientRect();

    // Set the "actual" size of the canvas
    // This is not the drawn size, but instead it is the number
    // of pixels inside the canvas
    this.#canvas.width = rect.width * dpr;
    this.#canvas.height = rect.height * dpr;
  }

  #initializeGraphHeightAndWidth() {
    this.#graphHeight = this.#canvas.height - this.#graphOffsetY * 2 - this.#labelAreaHeightX;
    this.#graphWidth = this.#canvas.width - this.#graphOffsetX * 2;
  }

  #drawBarsAndLabels(ctx) {
    const dataPointsCount = this.#data.length;
    const floatWidthOfBar = this.#graphWidth / dataPointsCount;
    ctx.save();
    ctx.lineWidth = Math.floor(floatWidthOfBar);
    ctx.strokeStyle = this.#color;
    ctx.lineCap = "round";
    ctx.beginPath();
    this.#data.forEach((element, i) => {
      const x = floatWidthOfBar * i + this.#graphOffsetX;
      const y = this.#graphHeight * element[1] / this.#maxBarSize;
      ctx.moveTo(x,this.#graphHeight + this.#graphOffsetY);
      ctx.lineTo(x,this.#graphHeight + this.#graphOffsetY - y);
      if ((i % 36) == 0) {
        ctx.font = this.#labelFont();
        ctx.fillStyle = this.#labelTextColorX;
        ctx.fillText(element[0], x, this.#graphHeight + this.#labelAreaHeightX);
      }
    });
    ctx.stroke();
    ctx.restore();
  }

  #labelFont() {
    return `${this.#labelFontSize}px san-serif`;
  }
}