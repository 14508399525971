import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const vimeoScript = document.querySelector('#vimeo-script');
    if (!vimeoScript) return;

    // bodyに埋め込まれてVimeo javascriptのloadとturbo:loadイベント
    // のどちらが早いかが一定ではないようなので、どちらにも対応する
    if (typeof(window.Vimeo) !== 'undefined') {
      this.player = this.#getPlayer();
    } else {
      vimeoScript.addEventListener('load', () => {
        this.player = this.#getPlayer();
      })
    }
  }

  async move(event) {
    event.preventDefault()
    const position = parseInt(event.currentTarget.dataset.position);
    const player = this.player;

    const duration = await player.getDuration();

    if (Number.isInteger(position) && (parseInt(position) <= duration)) {
      // For some unknown reason, player.setCurrentTime() does not work on the
      // first call, but it reliably works on the second call. On the first call
      // the progress indicator moves towards the specified time for a short time, and
      // then subsequently moves back to time 0. On the second and further calls, there is no issue
      // and it works fine. Here, we simply call setCurrentTime() twice.
      // player.setCurrentTime(1)
      //     .then(() => { player.setCurrentTime(position) })
      await player.setCurrentTime(position);
      await player.setCurrentTime(position);
    } else {
      alert('ERROR: playback position is larger than video duration');
    }
  }

  #getPlayer() {
    const iframe = document.querySelector('[data-name="js-materials-video"]');
    if (iframe) {
      return new Vimeo.Player(iframe);
    } else {
      return false;
    }
  };
}
