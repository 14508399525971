// https://www.driftingruby.com/episodes/syntax-highlighting-with-action-text

// import hljs from 'highlight.js'
import hljs from 'highlight.js/lib/common';
// # Recently, I've found that this path is required import hljs from 'highlight.js/lib/core'
// CSS is imported from SCSS files --> take a look

import ruby from 'highlight.js/lib/languages/ruby';
hljs.registerLanguage('ruby', ruby);

import erb from 'highlight.js/lib/languages/erb';
hljs.registerLanguage('erb', erb);

import bash from 'highlight.js/lib/languages/bash';
hljs.registerLanguage('bash', bash);

import javascript from 'highlight.js/lib/languages/javascript';
hljs.registerLanguage('javascript', javascript);

import perl from 'highlight.js/lib/languages/perl';
hljs.registerLanguage('perl', perl);

import python from 'highlight.js/lib/languages/python';
hljs.registerLanguage('python', python);

import php from 'highlight.js/lib/languages/php';
hljs.registerLanguage('php', php);

import java from 'highlight.js/lib/languages/java';
hljs.registerLanguage('java', java);

import c from 'highlight.js/lib/languages/c';
hljs.registerLanguage('c', c);

import cpp from 'highlight.js/lib/languages/cpp';
hljs.registerLanguage('cpp', cpp);

import css from 'highlight.js/lib/languages/css';
hljs.registerLanguage('css', css);

import scss from 'highlight.js/lib/languages/scss';
hljs.registerLanguage('scss', scss);

import django from 'highlight.js/lib/languages/django';
hljs.registerLanguage('django', django);

import dockerfile from 'highlight.js/lib/languages/dockerfile';
hljs.registerLanguage('dockerfile', dockerfile);

import sql from 'highlight.js/lib/languages/sql';
hljs.registerLanguage('sql', sql);

import swift from 'highlight.js/lib/languages/swift';
hljs.registerLanguage('swift', swift);

import yaml from 'highlight.js/lib/languages/yaml';
hljs.registerLanguage('yaml', yaml);

import xml from 'highlight.js/lib/languages/xml';
hljs.registerLanguage('xml', xml);

import typescript from 'highlight.js/lib/languages/typescript';
hljs.registerLanguage('typescript', typescript);

hljs.configure({ languages: ['ruby', 'erb', 'bash', 'javascript', 'perl', 'python', 'php', 'java',
                             'c', 'cpp', 'css', 'scss', 'django', 'dockerfile', 'swift', 'sql', 'typescript',
                             'yaml', 'xml'] })

document.addEventListener('turbo:load', (event) => {
  document.querySelectorAll('pre').forEach((block) => {
    hljs.highlightBlock(block);
  });
});
