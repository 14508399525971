// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import {Turbo} from '@hotwired/turbo-rails';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';

// https://stimulus.hotwired.dev/handbook/installing
import {Application} from "@hotwired/stimulus"
import {definitionsFromContext} from "@hotwired/stimulus-webpack-helpers"
// https://www.driftingruby.com/episodes/syntax-highlighting-with-action-text
// highlightをTrixより先に読み込むと、Trixのconfig.langがうまく書き換えられないので
// highlightを最後にimportしていたが最近は大丈夫っぽい
import 'highlight';

Turbo.setProgressBarDelay(500);

window.Stimulus = Application.start()
const context = require.context("../controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))

ActiveStorage.start();

require('partials/index');
require('partials/progressbar');
require('partials/validation');
// require('partials/viewport');
require('partials/breadcrumb');
require('partials/sidebar');
require('partials/video_frame');
require('partials/trix_extension');
require('partials/notifications');
require('partials/flash');
require('partials/avatar');
require('partials/manager_user');
require('partials/chart');

