window.addEventListener('turbo:load', () => {
  const form = document.querySelector('[data-form="js-validation-form"]');
  const submit = document.querySelector('[data-submit="js-validation-submit"]');
  const pass = document.querySelector('[data-pass="js-validation-pass"]');
  const rePass = document.querySelector('[data-pass="js-validation-repass"]');
  const mail = document.querySelector('[data-mail="js-validation-mail"]');
  const confirms = document.querySelectorAll('[data-input="js-validation-confirm"]');
  const editor = document.querySelector('[data-editor="js-validation-editor"]');
  const inputs = document.querySelectorAll('[data-target="js-validation-input"]');

  if (form) {
    form.addEventListener('input', () => {
      const isRequired = form.checkValidity();
      isRequired ? submit.classList.remove('is-disable') : submit.classList.add('is-disable');
    });
  }

  if (inputs) {
    inputs.forEach((input) => {
      let errMsg = document.createElement('p');
      errMsg.classList.add('invalid-feedback');
      input.addEventListener('input', () => {
        if (!input.value) {
          errMsg.textContent = '入力されていません';
          input.after(errMsg);
          input.classList.add('is-invalid');
        } else {
          errMsg.textContent = '';
          input.classList.remove('is-invalid');
        }
      });
    });
  }

  if (editor) {
    const target = editor;
    const qaTitile = document.querySelector('[data-title="js-validation-qatitle"]');
    let errMsg = document.createElement('p');
    errMsg.classList.add('invalid-feedback');

    const observer = new MutationObserver(() => {
      if (!target.hasChildNodes()) {
        errMsg.textContent = '入力されていません';
        target.classList.add('is-invalid');
        document.querySelector('[data-field="js-validation-field"]').after(errMsg);
        submit.classList.add('is-disable');
      } else if (!qaTitile && target.hasChildNodes()) {
        errMsg.textContent = '';
        target.classList.remove('is-invalid');
        submit.classList.remove('is-disable');
      } else {
        errMsg.textContent = '';
        target.classList.remove('is-invalid');
        qaTitile.value ? submit.classList.remove('is-disable') : submit.classList.add('is-disable');
        qaTitile.addEventListener('input', () => {
          qaTitile.value && target.hasChildNodes() ? submit.classList.remove('is-disable') : submit.classList.add('is-disable');
        });
      }
    });

    observer.observe(target, {
      childList: true,
      subtree: true,
      characterData: true,
    });

    observer.observe(qaTitile, {
      attributes: true,
    });
  }

  if (pass) {
    let errMsg = document.createElement('p');
    errMsg.classList.add('invalid-feedback');
    pass.addEventListener('input', () => {
      if (!pass.value) {
        errMsg.textContent = 'パスワードを入力してください';
        pass.after(errMsg);
        pass.classList.add('is-invalid');
      } else {
        errMsg.textContent = '';
        pass.classList.remove('is-invalid');
      }
    });
  }

  if (rePass) {
    let errMsg = document.createElement('p');
    errMsg.classList.add('invalid-feedback');
    confirms.forEach((confirm) => {
      confirm.addEventListener('input', () => {
        if (pass.value !== rePass.value) {
          errMsg.textContent = 'パスワードとパスワード再入力が一致していません';
          rePass.after(errMsg);
          rePass.classList.add('is-invalid');
        } else if (confirm.value) {
          errMsg.textContent = '';
          rePass.classList.remove('is-invalid');
        }
        const isInvalidAlls = [...confirms].reduce((isInvalidAll, confirm) => {
          let isInvalid = !confirm.value || isInvalidAll;
          if (confirm.value) isInvalid = isInvalidAll || pass.value !== rePass.value;
          return isInvalid;
        }, false);
        isInvalidAlls ? submit.classList.add('is-disable') : submit.classList.remove('is-disable');
      });
    });
  }

  if (mail) {
    let errMsg = document.createElement('p');
    errMsg.classList = 'invalid-feedback';
    mail.addEventListener('input', () => {
      if (!mail.value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)) {
        errMsg.textContent = '正しいメールアドレスを入力してください';
        mail.after(errMsg);
        mail.classList.add('is-invalid');
      } else {
        errMsg.textContent = '';
        mail.classList.remove('is-invalid');
      }
    });
  }
});
