window.addEventListener('turbo:load', () => {
    const vimeoScript = document.querySelector('#vimeo-script');
    if (!vimeoScript) return;

    // bodyに埋め込まれてVimeo javascriptのloadとturbo:loadイベント
    // のどちらが早いかが一定ではないようなので、どちらにも対応する
    if (typeof (window.Vimeo) !== 'undefined') {
        attachVimeoPlayerListeners();
    } else {
        vimeoScript.addEventListener('load', () => {
            attachVimeoPlayerListeners();
        })
    }
});

async function attachVimeoPlayerListeners() {
    const iframe = document.querySelector('[data-name="js-materials-video"]');
    if (!iframe) return;

    const beaconable = iframe.dataset.beaconable;

    if (iframe && beaconable) {
        const player = new Vimeo.Player(iframe);
        const materialId = iframe.id;
        let lastPositionInSeconds = 0;
        let lastStatusName = null;
        const timeInterval = 10;
        const duration = await player.getDuration();

        player.on('play', (event) => {
            sendBeaconThenUpdateButton(materialId, event.seconds, duration)
            lastStatusName = statusName(event.seconds, duration)
        });

        player.on('timeupdate', (event) => {
            const statusNameChanged = lastStatusName !== statusName(event.seconds, duration)
            const timeIntervalElapsed = event.seconds > lastPositionInSeconds + timeInterval

            if (statusNameChanged || timeIntervalElapsed) {
                lastPositionInSeconds = event.seconds;
                sendBeaconThenUpdateButton(materialId, event.seconds, duration);
            }
            lastStatusName = statusName(event.seconds, duration)
        });

        player.on('ended', (event) => {
            sendBeaconThenUpdateButton(materialId, event.seconds, duration);
        });

        // If the URL is something like https://sodatech.jp/materials/12354?t=40,
        // then set the position of the Vimeo Player to 40 seconds.
        player.on('loaded', () => {
            const urlParams = new URLSearchParams(window.location.search);
            const time = parseInt(urlParams.get('t'));

            if (time) {
                // For some unknown reason, player.setCurrentTime() does not work on the
                // first call, but it reliably works on the second call. On the first call
                // the progress indicator moves towards the specified time for a short time, and
                // then subsequently moves back to time 0. On the second and further calls, there is no issue
                // and it works fine. Here, we simply call setCurrentTime() twice.
                player.setCurrentTime(1).then(() => {
                    player.setCurrentTime(time)
                });
            }
        })
    }

    const sendMaterialProgressBeacon = (status, materialId, retries = 1, callback = function () {
    }) => {
        const csrfToken = document.getElementsByName('csrf-token')[0].content;

        fetch('/material_progresses', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'X-CSRF-Token': csrfToken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({material_id: materialId, status: status}),
        }).then(response => {
            if (response.ok) {
                callback();
            } else {
                console.log('Failed. Server returned non-OK status: ' + response.status);
            }
        }).catch(error => {
            console.log('Failed to receive response from server. Retrying. Retries remaining: ' + retries);
            const remainingRetries = retries - 1;
            if (!remainingRetries) {
                throw error;
            }
            return sendMaterialProgressBeacon(status, callback, remainingRetries);
        });
    };

    function statusName(currentTimeInSeconds, videoEndTimeInSeconds) {
        const earlyEndTimeWindow = 10 // Send 'ended' instead of 'progressed' if past given seconds before video end

        return currentTimeInSeconds >= (videoEndTimeInSeconds - earlyEndTimeWindow) ? 'ended' : 'progressed'
    }

    function sendBeaconThenUpdateButton(materialId, currentTimeInSeconds, videoEndTimeInSeconds) {
        const myStatusName = statusName(currentTimeInSeconds, videoEndTimeInSeconds);

        if (myStatusName === 'progressed') {
            sendMaterialProgressBeacon('progressed', materialId)
        } else if (myStatusName === 'ended') {
            const nextActionTurboFrameElement = document.getElementById('next_actions_in_material');
            sendMaterialProgressBeacon('ended', materialId, 1,
                function () {
                    // サーバで完了actionを実行後、次のアクションのボタンを再読み込み。
                    // （例えば「テストを受ける」とか「完了して次の教材に行く」とかのボタンを表示する。
                    // 今のところは全ページを読み込んで、turboframeのところだけを書き換えている
                    //
                    // なお最初からsrcを設定していると、最初にmaterials#showを読み込む時に不具合がある
                    // ので、後からsrcを指定する。srcを指定したら、reload()なしでもeager loadしてくれる。
                    if (nextActionTurboFrameElement) {
                        nextActionTurboFrameElement.src = '/materials/' + materialId + '/buttons';
                    }
                });
        }
    }
}
