import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ["form", "item", "query"]

    connect() {
    }

    send(event) {
        this.formTarget.requestSubmit();
    }

    focusList(event) {
        this.itemTarget.focus()
        this.#preventEvent(event)
    }

    focusNext(event) {
        const currentIndex = this.itemTargets.indexOf(event.target)
        if (currentIndex !== -1 && currentIndex < this.itemTargets.length - 1) {
            this.itemTargets[currentIndex + 1].focus()
        } else {
        }
        this.#preventEvent(event)
    }

    focusPrevious(event) {
        const currentIndex = this.itemTargets.indexOf(event.target)
        if (0 < currentIndex && currentIndex < this.itemTargets.length) {
            this.itemTargets[currentIndex - 1].focus()
        } else {
            this.queryTarget.focus();
        }
        this.#preventEvent(event)
    }

    #preventEvent(event) {
        event.preventDefault();
        event.stopPropagation();
    }
}
