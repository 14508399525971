window.addEventListener('turbo:load', () => {
  const changeConfirms = document.querySelectorAll('[data-change-confirm]');
  changeConfirms.forEach( (changeConfirm) => {
    changeConfirm.addEventListener('change', (event) => {
      const checked = event.target.checked;
      const message = event.target.dataset.changeConfirm;
      if (!checked && !confirm(message)) {
        event.target.checked = true;
      }
    })
  })

  const changeProhibits = document.querySelectorAll('[data-change-prohibit]');
  changeProhibits.forEach( (changeProhibit) => {
    changeProhibit.addEventListener('click', (event) => {
      const message = event.target.dataset.changeProhibit;
      event.preventDefault();
      alert(message);
    })
  })
})
