import { Controller } from '@hotwired/stimulus';

// noinspection JSUnresolvedVariable
export default class extends Controller {
  static values = {
    arcEnd1: Number, // As radian: 1.0 means 1pi = 180 degrees
    arcEnd2: Number, // As radian: 1.0 means 1pi = 180 degrees
  }

  connect() {
    // If the current page is already in Turbo's cache, then `connect()` will
    // be called twice -- first when the cache loads, and second when the data
    // is received from the server.
    // To prevent this causing the animation to happen twice, we prevent this
    // animating when reading from the cache.
    if (document.documentElement.hasAttribute("data-turbo-preview")) {
      return;
    }
    const canvas = this.element;

    // Get the DPR and size of the canvas
    const dpr = window.devicePixelRatio;
    const rect = canvas.getBoundingClientRect();
    // debugger;

    // Set the "actual" size of the canvas
    // This is not the drawn size, but instead it is the number
    // of pixels inside the canvas
    canvas.width = rect.width * dpr;
    canvas.height = rect.width * dpr;

    const ctx = canvas.getContext('2d');
    const animationDuration = 0.5; // seconds
    const numberOfRedraws = 60 * animationDuration;
    const cursorIncrement = 100 / numberOfRedraws;
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const radius1 = Math.min(centerX, centerY) * 0.85;
    const radius2 = Math.min(centerX, centerY) * 0.55;
    const lineWidth = Math.min(centerX, centerY) * 0.275;

    ctx.lineCap = "round";
    ctx.lineWidth = lineWidth;

    // ProgressArc is drawn from radian 0 to arcEnd (as arcEnd * pi radian)
    // with a method call to `draw(context, cursor)`
    // `cursor` represents (using a value from 0 to 100) the progress of
    // the animation.
    // The initial cursor value can be specified by initialCursor.
    function ProgressArc(x, y, radius, color, arcEnd, initialCursor = 0, lightnessAtCursorZero = 0.5) {
      this.x = x;
      this.y = y;
      this.radius = radius;
      this.color = color;
      this.arcEnd = arcEnd;
      this.cursor = initialCursor;
      this.lightnessAtCursorZero = lightnessAtCursorZero;
      this.draw = (context, cursor) => {
        context.save();
        context.beginPath();
        context.strokeStyle = colorAtCursor();
        context.arc(this.x, this.y, this.radius,
                    (this.cursor / 100) * this.arcEnd,
                    (cursor / 100) * this.arcEnd, false);
        context.stroke();
        context.restore();
        this.cursor = cursor;
      }

      // The color of the cursor at a specific cursor position
      const colorAtCursor = () => {
        let lightness = this.lightnessAtCursorZero + (1 - this.lightnessAtCursorZero) * (this.cursor / 100);
        let red = Math.floor(parseInt(this.color.substring(1,3), 16) * lightness);
        let green = Math.floor(parseInt(this.color.substring(3,5), 16) * lightness);
        let blue = Math.floor(parseInt(this.color.substring(5,7), 16) * lightness);
        return `rgb(${red}, ${green}, ${blue})`
      }
    }

    const progressArc1 = this.arcEnd1Value ? new ProgressArc(centerX, centerY, radius1, '#00a0e9',
                                         Math.PI * this.arcEnd1Value) : null;
    const progressArc2 = this.arcEnd2Value ? new ProgressArc(centerX, centerY, radius2, '#666666',
                                         Math.PI * this.arcEnd2Value) : null;

    function draw(cursor) {
      progressArc1 && progressArc1.draw(ctx, cursor);
      progressArc2 && progressArc2.draw(ctx, cursor);

      cursor = cursor + cursorIncrement;
      if (cursor <= 100) {
        requestAnimationFrame(() => draw(cursor))
      }
    }

    draw(0);
  }
}