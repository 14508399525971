window.addEventListener('turbo:load', () => {
  const fileField = document.getElementById('user_avatar');
  const avatarDeleteBtn = document.getElementById('avatar-delete-btn');
  const avatarPreview = document.getElementById('avatar-preview');
  const deleteField = document.getElementById('user__delete_avatar');

  if (fileField) {
    fileField.onchange = (event) => {
      const file = event.target.files[0];
      const reader  = new FileReader();
      const mimeType = file.type;
      const allowedMimeTypes = ['image/jpeg', 'image/png', 'image/svg+xml', 'image/gif']
      const size = file.size;
      const maxFileSize = 10485760; // 10 MB

      if (!allowedMimeTypes.includes(mimeType)) {
        event.preventDefault();
        alert('画像のみがアップロードできます');
      }

      if (size > maxFileSize) {
        event.preventDefault();
        alert('ファイルサイズが制限(10MB)を超えています');
      }

      reader.onloadend = () => {
        avatarPreview.src = reader.result;
        avatarDeleteBtn.style.visibility = 'visible';
        deleteField.value = null;
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }

    avatarDeleteBtn.onclick = (event) => {
      event.preventDefault();
      fileField.value = '';
      avatarPreview.src = avatarPreview.dataset.default;
      avatarDeleteBtn.style.visibility = 'hidden';
      deleteField.value = 1;
    }
  }
})
