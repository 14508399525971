window.addEventListener('turbo:load', () => {
  const breadcrumbs = document.querySelectorAll('[data-link="js-breadcrumb-link"]');
  const breadcrumbBar = document.querySelector('.curriculum-breadcrumb');
  const maxLength = 19;

  if (breadcrumbs) {
    breadcrumbs.forEach((breadcrumb) => {
      if (!breadcrumb.dataset.textOmitted) {
        const fullLength = breadcrumb.textContent;
        const fullText = document.createElement('p');
        fullText.classList.add('curriculum-breadcrumb__text');
        fullText.textContent = fullLength;
        if (fullLength.length > maxLength) {
          breadcrumb.addEventListener('mouseover', () => {
            breadcrumbBar.classList.add('show-tooltip');
            breadcrumb.classList.add('is-active');
          });
          breadcrumb.addEventListener('mouseleave', () => {
            breadcrumbBar.classList.remove('show-tooltip');
            breadcrumb.classList.remove('is-active');
          });
        }

        const omittedContent = (string) => {
          if (string.length > maxLength) {
            return string.substr(0, maxLength) + '…';
          }
          return string;
        };
        breadcrumb.textContent = omittedContent(breadcrumb.textContent);
        breadcrumb.appendChild(fullText);
        breadcrumb.dataset.textOmitted = true;
      }
    });
  }

  const curriculumBreadcrumbBar = document.querySelector('.curriculum-breadcrumb');
  if (curriculumBreadcrumbBar) {
    curriculumBreadcrumbBar.scrollTo({
      left: curriculumBreadcrumbBar.scrollWidth,
      behavior: 'smooth'
    })
  }
});
