// This provides an easy way to toggle the visibility of an element in response
// to a Javascript event.
//
// Essentially, it toggles the class (specified by `data-toggle-visibility-class-value`)
// of its targets (specified by `data-toggle-visibility-target="toggleable"`)
//
// Usage:
// <div data-controller="toggle-visibility" data-toggle-visibility-class-value="display-none">
//    <a href="javascript:void(0)" data-action="click->toggle-visibility#toggle">
//
//    <div className="display-none"
//         data-toggle-visibility-target="toggleable"
//         data-toggle-visibility-class-value="display-none">
//
//      ... the stuff that we want to show/hide ....
//
//    </div>
import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['toggleable']
    static values = {
        class: {type: String, default: "display-none"}
    }

    toggle(event) {
        event.preventDefault()
        this.toggleableTargets.forEach((element) => {
            element.classList.toggle(this.classValue)
        })
    }
}
