// Monitoring of study progress through UserActivities.
//
// Purpose
// =====
//
// There are two reasons for tracking study progress in the Sodatech system.
//
// 1. Management & Gamification -- By showing the study progress to each student's
//    managers, each Manager will be able to assess the achievements of each student.
//    Additionally, but showing this information to both the student and to their
//    peers, we hope that the student will be motivated to study harder.
// 2. Govenment Subsidy -- As part of an effort to increase the number of
//    employees with Information Technology skills, the Japanese government will subsidise
//    IT training fees. However, to be eligible for this subsidy, Sodatech has to
//    be categorised as an LMS (Learning Management System) as defined by government
//    guidelines. We need to track study progress to be able to meet these guidelines.
//
// With regards to "Management & Gamification", Hayato Sasano is leading this initiative.
// We plan to create a system similar to the "Move" app on the iPhone, where progress
// is visualised as rings, and we motivate the user to "close" their rings.
//
// With regards to "Government Subsidy", Minako Yamamoto is leading this project.
// The requirements are as follows.
//
// a. We must be able to report who studied which section/material when.
//    The report must say, for example, "user 1 studied material A from 10:00-10:28 on 2023-01-23"
// b. We must report how long each student studied down to a resolution of 1 minute.
// c. Progress must be reported -- we assume the number of materials studied per course to be sufficient.
// d. Skill acquisition must be reported -- we assume that the current exams should be sufficient.
// e. The above report must be printable onto paper.
//
// Comparing these two requirements, the Governtment Subsidy requirement is by far
// the more complex.
//
// For study progress tracking purposes, we will focus on meeting this.
//
//
// Scheme
// ====
//
// Tracking the time that a student spent studying is tricky for the following reasons.
//
// 1. Tracking actual activity, such as clicking on links or answering questions, is
//    not sufficient to track view time. The user may be spending time watching a
//    video, and if this is the case, there may not be any activity sent to the server.
// 2. To track time watching videos or reading instructions, a better idea would be to
//    track the time that the web page is being shown to the user.
//    a. A question is, does the time the web page is being shown to the user equal the
//       time the user is studying? What if the user has the web page in the background
//       while they are typing memos or code in an editor?
//    b. Will the browser be able to differentiate between time spent as a background tab
//       and when the tab is front and focused?
//    c. If the user has two tabs open simultaneously, both showing a Sodatech page,
//       will we track twice the time, or will we only track the time once. It is
//       probably better to track only once, but how should we do that?
//
// Our current idea is the following.
//
// 1. We will use the [Page Visibility API](https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API).
//    As described and also per our testing, this can detect when the page is hidden,
//    either by being a background tag, being hidden into the Dock (Mac), being hidden
//    by an overlapping window, being on a separate workspace, etc.
//    We will only count time when the page is Visible as defined by this API.
// 2. Only one UserActivity entry can be created per User per minute. This means that if
//    the User has two browser windows open on 2023-01-23 12:02, there will only be
//    on UserActivity entry and so that User will have only one minute of study activity
//    registered. The UserActivity will be associated to either one of the two pages
//    shown, and the choice will be essentially random.
// 3. Each open browser window will send a beacon to the Sodatech server to register
//    activity. To ensure that there will be at least one beacon sent per minute, we
//    send a beacon at an interval significatly less that a minute (note that if
//    we receive two or more beacons per any given minute, only the first one will
//    be registered)
// 4. Each beacon will register what the User is studying if this can be acertained.
//    Typically, we will try to register the Course, Unit and Material depending
//    on which page is shown. In cases where this is not possible, we will register
//    the studied material to be unknown.
//
// Implementation
// ====
//
// Below is the description for the beacon implementation. For the server implementation,
// refer to the comments on `app/controllers/user_activities_controller.rb`.
//
// Outline
// ----
//
// We use Stimulus JS to handle attaching/detatching of the necessary event
// handlers and the creation/destruction of the timers.
//
// The Stimulus JS controllers are set up so that when the following HTML is
// contained in the DOM, then the appropriate beacons are sent.
//
// <div data-controller="user-activity"
//      data-action="visibilitychange@document->user-activity#sendActivity"
//      data-logged-in="true"
//      data-url="/user_activities"
//      data-course-id="1"
//      data-unit-id="2"
//      data-material-id="3"
//      data-beacon-interval="30" >
// </div>
//
// The current requirement is to track every minute of study. Since we
// cannot fully rely on `setInterval()` being accurate, we set the interval
// to be significantly shorter than one minute to ensure that a beacon is captured.
//
// We send the beacon (`sendActivity`) on the following events
//
// 1. As per the `data-action` attribute above, when the page visibility changes
//    (both when it becomes visible and when it becomes hidden).
// 2. Once every number of seconds as specified in the `data-beacon-interval`
//    attribute. However, if the document is hidden, then the beacon will not be
//    sent.
//
// This only happens when the stimulus controller is connected (the above DOM
// element is loaded onto the page). Therefore, we can control which pages
// fire this event by whether or not we include the above DOM element on a page.
// However, we currently fire this for every User page (by inclusion in `application.html.erb`)
//
// According to my tests on MacOS, the firing of this even is very intelligent.
// Basically, it will fire if any portion of the page is visible on the screen,
// but on if the page is fully hidden. This includes hiding the page by moving
// to a different workspace, hiding the page in the dock, or simply fully covering
// it with a different window.
//
// https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
import { Controller } from '@hotwired/stimulus';


export default class extends Controller {
  static values = {
    loggedIn: Boolean,
    courseId: Number, // defaults to 0
    unitId: Number, // defaults to 0
    materialId: Number, // defaults to 0
  };
  #intervalHandle

  connect() {
    const beaconInterval = parseInt(this.element.dataset.beaconInterval); // in seconds

    this.sendActivity(); // The first beacon happens on connect
    this.#intervalHandle = setInterval(
      () => {
        if (document.visibilityState === 'visible') {
          this.sendActivity();
        }
      },
      beaconInterval * 1000
    );
  }

  disconnect() {
    clearInterval(this.#intervalHandle);
  }

  sendActivity() {
    if (!this.loggedInValue) {
      return;
    }

    fetch(this.element.dataset.url, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'X-CSRF-Token': this.#csrfToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_activity: {
          page_url: document.location.href,
          page_title: document.title,
          // if courseIdValue, unitIdValue, materialIdValue are 0,
          // then send null to server.
          course_id: this.courseIdValue ? this.courseIdValue : null,
          unit_id: this.unitIdValue ? this.unitIdValue : null,
          material_id: this.materialIdValue ? this.materialIdValue : null,
        }
      }),
    }).then(response => {
      if (response.ok) {
      }
    });
  }

  #csrfToken() {
    return document.getElementsByName('csrf-token')[0].content;
  }
}
