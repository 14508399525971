// Gradular Reveal Controller
//
// This allows you to hide empty input fields in your HTML
// and reveal them row by row.
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ "revealable" ]

  revealNext(event) {
    event.preventDefault();
    const elementToShow = this.revealableTargets.
                            find( row => row.classList.contains('d-none') );
    if (elementToShow) {
      elementToShow.classList.remove('d-none');
    }
  }
}
