window.addEventListener('turbo:load', () => {
  const modalIcons = document.querySelectorAll('[data-icon="js-modal-icon"]');
  if (modalIcons) {
    modalIcons.forEach((modalIcon) => {
      modalIcon.addEventListener('click', () => {
        modalIcon.parentElement.classList.add('is-remove');
      });
    });
  }
});
