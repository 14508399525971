window.addEventListener('turbo:load', () => {
  const sideToggles = document.querySelectorAll('[data-toggle="js-side-toggle"]');
  if (sideToggles) {
    sideToggles.forEach((sideToggle) => {
      sideToggle.addEventListener('click', (event) => {
        // .side-sub__list はtoggleの子要素を包む要素
        // ここに含まれるものをクリックした場合は閉じない
        // 一番上の階層をクリックしたときだけ開閉する
        if (!event.target.closest('.side-sub__list')) {
          sideToggle.classList.toggle('is-open');
        }
      });
    });
  }

  // ハンバーガーメニュー
  const spToggle = document.querySelector('[data-toggle="js-header-toggle"]');
  const sideBar = document.querySelector('.side');
  const body = document.querySelector('body');
  if (spToggle && sideBar) {
    spToggle.addEventListener('click', () => {
      spToggle.classList.toggle('is-open');
      sideBar.classList.toggle('is-open');
    });

    // toggleとsidebarの外をクリックするとsidebarをcloseする
    body.addEventListener('click', (event) => {
      let closest = event.target.closest('.side') || event.target.closest('.header-sp__toggle');
      if (!closest && spToggle.classList.contains('is-open')) {
        spToggle.classList.toggle('is-open');
        sideBar.classList.toggle('is-open');
      }
    })
  }
});
