window.addEventListener('turbo:load', () => {
    // answers-question
    const dangers = document.querySelectorAll('[data-name="js-bg-danger"]');
    if (dangers) {
        dangers.forEach((danger) => {
            danger.closest('[data-name="js-alert-danger"]').classList.add('alert--answer');
        });
    }

    // content-table
    const trButtons = document.querySelectorAll('[data-name="js-content-tr"]');
    if (trButtons) {
        trButtons.forEach((trButton) => {
            trButton.addEventListener('click', () => {
                (window.getSelection().toString() === "") && Turbo.visit(trButton.dataset['href']);
            });
        });
    }

    // course-card__unit
    const courseLinks = document.querySelectorAll('[data-name="js-courses-card"]');
    const courseUnits = document.querySelectorAll('[data-name="js-courses-unit"]');
    if (courseUnits && courseLinks) {
        courseLinks.forEach((courseLink) => {
            courseLink.addEventListener('click', () => {
                (window.getSelection().toString() === "") && Turbo.visit(courseLink.dataset['href']);
            });
        });
        courseUnits.forEach((courseUnit) => {
            courseUnit.addEventListener('click', (target) => {
                target.stopPropagation();
                courseUnit.classList.toggle('is-open');
            });
        });
    }

    // material__annotations
    const materialAnnotations = document.querySelectorAll('[data-name="js-materials-annotations"]');
    if (materialAnnotations) {
        materialAnnotations.forEach((materialAnnotation) => {
            const toggleButton = materialAnnotation.querySelector('[data-name="js-toggle"]')
            if (toggleButton) {
                toggleButton.addEventListener('click', (target) => {
                    materialAnnotation.classList.toggle('is-open');
                });
            }
        });
    }

    // messages
    const messages = document.querySelectorAll('[data-message="js-omit-message"]');
    const maxLength = 49;
    if (messages) {
        messages.forEach((message) => {
            const omittedContent = (string) => {
                if (string.length > maxLength) {
                    return string.substr(0, maxLength) + '…';
                }
                return string;
            };
            message.textContent = omittedContent(message.textContent);
        });
    }

    // manager-select
    const selectButton = document.querySelector('[data-button="js-manager-button"]');
    const selectRadios = document.querySelectorAll('[data-radio="js-manager-radio"]');
    const selectButtonText = document.querySelector('[data-button="js-manager-button-text"]');

    if (selectButton) {
        selectButton.addEventListener('click', () => {
            selectButton.classList.toggle('is-active');
        });
    }
    if (selectRadios) {
        selectRadios.forEach((selectRadio) => {
            selectRadio.addEventListener('click', () => {
                Turbo.visit(selectRadio.value);
            });
            const searchName = location.search.split('role=')[1];
            if (selectRadio.id === searchName) {
                selectRadio.parentElement.classList.add('is-active');
                selectRadios[0].parentElement.classList.remove('is-active');
                selectButtonText.textContent = selectRadio.parentElement.textContent;
            }
        });
    }
});
