import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ["modal", "backdrop", "closeBtn", "defaultFocus"]

    connect() {
    }

    open() {
        this.modalTarget.classList.add("show")
        this.backdropTarget.classList.add("show")
        this.defaultFocusTarget.focus()
    }

    close() {
        this.modalTarget.classList.remove("show")
        this.backdropTarget.classList.remove("show")
    }
}
