import {Controller} from '@hotwired/stimulus';

// Any element that has data-scroll-target="top" will scrollIntoView()
// on that element when shown.
//
// This allows us to circumvent the issue where `fetch()` will remove the
// anchor on redirect and hence makes it difficult for us to redirect with
// scroll to a certain element.
//
// There is also an issue with Hotwire Turbo where following a link with
// an anchor will highlight the anchor with a blue frame around the element
// which is annoying. scrollIntoView does not exhibit this behaviour.
//
// We need a timeout until the target is ready to be scrolled.
export default class extends Controller {
    static targets = ["top"]

    connect() {
        setTimeout(() => {
            this.topTarget.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
        }, 500)
    }
}
